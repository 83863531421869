import {
  DashboardOutlined,
  LayoutOutlined,
  FileDoneOutlined,
  RobotOutlined,
  CompassOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  BarChartOutlined,
  DropboxOutlined,
  CalendarOutlined,
  // ToolOutlined,
  MergeCellsOutlined,
  CarOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import _ from 'lodash';

const paymentsNavTree = [{
  key: 'payments-group',
  path: `${APP_PREFIX_PATH}/payments-group`,
  title: 'sidenav.paymentsGroup',
  fromBackend: '',
  navItem: true,
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'payments',
      path: `${APP_PREFIX_PATH}/payments`,
      title: 'sidenav.payments',
      fromBackend: 'Payments',
      navItem: true,
      icon: LayoutOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'payments-paymentsList',
          path: `${APP_PREFIX_PATH}/payments/payments-list`,
          title: 'sidenav.payments.paymentsList',
          fromBackend: 'Payments',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'payments-cashFlow',
          path: `${APP_PREFIX_PATH}/payments/cash-flow`,
          title: 'sidenav.payments.cashFlow',
          fromBackend: 'Cash Flow',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'payments-paymentAccounts',
          path: `${APP_PREFIX_PATH}/payments/payment-accounts`,
          title: 'sidenav.payments.paymentAccounts',
          fromBackend: 'Payment Accounts',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'payments-paymentsFromGeorgia',
          path: `${APP_PREFIX_PATH}/payments/payments-from-georgia`,
          title: 'sidenav.payments.paymentsFromGeorgia',
          fromBackend: 'Payments of Georgia',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}]

const shippingNavTree = [
    {
      key: 'shippingList',
      path: `${APP_PREFIX_PATH}/shipping/shipping-list`,
      title: 'sidenav.shipping.shippingList',
      fromBackend: 'Shipping List',
      navItem: false,
      icon: FileDoneOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'shippingHistoryList',
      path: `${APP_PREFIX_PATH}/shipping-history/shipping-history-list`,
      title: 'sidenav.shipping.shippingHistoryList',
      fromBackend: 'Shipping History',
      navItem: false,
      icon: FileDoneOutlined,
      breadcrumb: false,
      submenu: []
    }
]

const shippingLastVehiclesNavTree = [
  {
    key: 'shippingLastVehiclesList',
    path: `${APP_PREFIX_PATH}/last-vehicles/last-vehicles-list`,
    title: 'sidenav.lastVehicles.lastVehiclesList',
    fromBackend: 'Last Vehicles List',
    navItem: false,
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: []
  },
];

const purchasedCarNavTree = [
  {
    key: 'purchasedCarList',
    path: `${APP_PREFIX_PATH}/purchased-car/purchased-car-list`,
    title: 'sidenav.purchasedCar.purchasedCarList',
    fromBackend: 'Purchased Cars',
    navItem: false,
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: []
  },
];

const bidsNavTree = [
  {
    key: 'bidsList',
    path: `${APP_PREFIX_PATH}/bids/bids-list`,
    title: 'sidenav.bids.bidsList',
    fromBackend: 'Bids List',
    navItem: false,
    icon: CalendarOutlined,
    breadcrumb: false,
    submenu: []
  },
];


const usersNavTree = [{
  key: 'users-group',
  path: `${APP_PREFIX_PATH}/users-group`,
  title: 'sidenav.usersGroup',
  fromBackend: '',
  navItem: true,
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'sidenav.users',
      fromBackend: 'Users',
      navItem: true,
      icon: LayoutOutlined,
      breadcrumb: false,
      submenu: [
        {
            key: 'users-customers-customersList',
            path: `${APP_PREFIX_PATH}/users/customers/customers-list`,
            title: 'sidenav.users.customers.customersList',
            fromBackend: 'Customers',
            navItem: false,
            icon: RobotOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
          key: 'users-managers-managersList',
          path: `${APP_PREFIX_PATH}/users/managers/managers-list`,
          title: 'sidenav.users.managers.managersList',
          fromBackend: 'Managers',
          navItem: false,
          icon: RobotOutlined,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}]

const librariesNavTree = [{
  key: 'libraries-group',
  path: `${APP_PREFIX_PATH}/libraries-group`,
  title: 'sidenav.librariesGroup',
  fromBackend: '',
  navItem: true,
  icon: false,
  breadcrumb: false,
  submenu: [
    {
      key: 'libraries',
      path: `${APP_PREFIX_PATH}/libraries`,
      title: 'sidenav.libraries',
      fromBackend: 'Libraries',
      navItem: true,
      icon: CompassOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'libraries-line',
          path: `${APP_PREFIX_PATH}/libraries/line`,
          title: 'sidenav.libraries.line',
          fromBackend: 'Lines',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-port-arrival',
          path: `${APP_PREFIX_PATH}/libraries/port-arrival`,
          title: 'sidenav.libraries.portArrival',
          fromBackend: 'Ports of arrival',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-port-loading',
          path: `${APP_PREFIX_PATH}/libraries/port-loading`,
          title: 'sidenav.libraries.portLoading',
          fromBackend: 'Ports of loading',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-container-cost',
          path: `${APP_PREFIX_PATH}/libraries/container-cost`,
          title: 'sidenav.libraries.containerCost',
          fromBackend: 'Container cost',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-container-cost-adjustment',
          path: `${APP_PREFIX_PATH}/libraries/container-cost-adjustment`,
          title: 'sidenav.libraries.containerCostAdjustment',
          fromBackend: 'Container cost Adjustment',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-tariff-template',
          path: `${APP_PREFIX_PATH}/libraries/tariff-template`,
          title: 'sidenav.libraries.tariffTemplate',
          fromBackend: 'Tariffs Template',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-vehicle-type',
          path: `${APP_PREFIX_PATH}/libraries/vehicle-type`,
          title: 'sidenav.libraries.vehicleType',
          fromBackend: 'Vehicle Type',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-client-invoice-substitution',
          path: `${APP_PREFIX_PATH}/libraries/client-invoice-substitution`,
          title: 'sidenav.libraries.clientInvoiceSubstitution',
          fromBackend: 'Invoice Substitution',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-assignee-category',
          path: `${APP_PREFIX_PATH}/libraries/assignee-category`,
          title: 'sidenav.libraries.assigneeCategory',
          fromBackend: 'Assignee Category',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-buyer-id-comparison',
          path: `${APP_PREFIX_PATH}/libraries/buyer-id-comparison`,
          title: 'sidenav.libraries.buyerIdComparison',
          fromBackend: 'Buyer Id Comparison',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-types-of-payments',
          path: `${APP_PREFIX_PATH}/libraries/types-of-payments`,
          title: 'sidenav.libraries.typesOfPaymentsList',
          fromBackend: 'Accounting Types of Payments',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-types-of-costs',
          path: `${APP_PREFIX_PATH}/libraries/types-of-costs`,
          title: 'sidenav.libraries.typesOfCostsList',
          fromBackend: 'Accounting Types of Costs',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-tariffs-for-calculator',
          path: `${APP_PREFIX_PATH}/libraries/tariffs-for-calculator`,
          title: 'sidenav.libraries.tariffsForCalculator',
          fromBackend: 'Tariffs for calculator',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-removed-vehicle',
          path: `${APP_PREFIX_PATH}/libraries/removed-vehicle`,
          title: 'sidenav.libraries.removedVehicle',
          fromBackend: 'Removed vehicle',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-auction-fee-copart',
          path: `${APP_PREFIX_PATH}/libraries/auction-fee-copart`,
          title: 'sidenav.libraries.auctionFeeCopart',
          fromBackend: 'Auction Fees',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-auction-fee-iaai',
          path: `${APP_PREFIX_PATH}/libraries/auction-fee-iaai`,
          title: 'sidenav.libraries.auctionFeeIaai',
          fromBackend: 'Auction Fees',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-auction-account',
          path: `${APP_PREFIX_PATH}/libraries/auction-account`,
          title: 'sidenav.libraries.auctionAccount',
          fromBackend: 'Auctions Accounts',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-questionnaire-templates',
          path: `${APP_PREFIX_PATH}/libraries/questionnaire-template`,
          title: 'sidenav.libraries.questionnaireTemplate',
          fromBackend: 'Questionnaire Template',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-port-arrival-warehouse',
          path: `${APP_PREFIX_PATH}/libraries/port-arrival-warehouse`,
          title: 'sidenav.libraries.portArrivalWarehouse',
          fromBackend: 'Warehouses of ports of arrival',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'libraries-broker',
          path: `${APP_PREFIX_PATH}/libraries/broker`,
          title: 'sidenav.libraries.broker',
          fromBackend: 'Brokers',
          navItem: false,
          icon: false,
          breadcrumb: false,
          submenu: []
        },
      ]
    }
  ]
}]

const requestsNavTree = [{
  key: 'requests-group',
  path: `${APP_PREFIX_PATH}/requests-group`,
  title: 'sidenav.requestsGroup',
  fromBackend: '',
  navItem: true,
  icon: DesktopOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'requests',
      path: `${APP_PREFIX_PATH}/requests`,
      title: 'sidenav.requests',
      fromBackend: 'Requests',
      navItem: true,
      icon: DesktopOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'unverified-documents',
          path: `${APP_PREFIX_PATH}/requests/unverified-documents`,
          title: 'sidenav.requests.unverifiedDocuments',
          fromBackend: 'Unverified Documents',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
  ]
}]

const toolsNavTree = [{
  key: 'tools-group',
  path: `${APP_PREFIX_PATH}/tools-group`,
  title: 'sidenav.toolsGroup',
  fromBackend: '',
  navItem: true,
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'tools',
      path: `${APP_PREFIX_PATH}/tools`,
      title: 'sidenav.tools',
      fromBackend: 'Tools',
      navItem: true,
      icon: InfoCircleOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'information-messages',
          path: `${APP_PREFIX_PATH}/tools/information-messages`,
          title: 'sidenav.tools.informationMessages',
          fromBackend: 'Information messages',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'import-from-lion-trans-ge',
          path: `${APP_PREFIX_PATH}/tools/import-from-lion-trans-list`,
          title: 'sidenav.tools.importFromLionTransGE',
          fromBackend: 'Import from LionTrans GE',
          navItem: false,
          icon: RobotOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'tools-telegram-bot-messages',
          path: `${APP_PREFIX_PATH}/tools/telegram-bot-messages`,
          title: 'sidenav.tools.telegramBotMessages',
          fromBackend: 'Telegram_bot messages',
          navItem: false,
          icon: RobotOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'instructions-templates',
          path: `${APP_PREFIX_PATH}/tools/instructions-templates`,
          title: 'sidenav.tools.instructionsTemplates',
          fromBackend: 'Instructions & Templates',
          navItem: false,
          icon: RobotOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'happy-birthday-templates',
          path: `${APP_PREFIX_PATH}/tools/happy-birthday-templates`,
          title: 'sidenav.tools.happyBirthdayTemplates',
          fromBackend: 'Happy birthday templates',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'questionnaires',
          path: `${APP_PREFIX_PATH}/tools/questionnaires`,
          title: 'sidenav.tools.questionnaires',
          fromBackend: 'Questionnaire',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'offer',
          path: `${APP_PREFIX_PATH}/tools/offers`,
          title: 'sidenav.tools.offer',
          fromBackend: 'Rules & Policies',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}]

const helpDeskNavTree = [
  {
    key: 'helpDesk',
    path: `${APP_PREFIX_PATH}/help-desk/all-tickets`,
    title: 'sidenav.helpDesk',
    fromBackend: 'HelpDesk',
    navItem: false,
    icon: MessageOutlined,
    breadcrumb: false,
    submenu: []
  },
]

const helpDeskAuditNavTree = [
  {
    key: 'helpDeskAudit',
    path: `${APP_PREFIX_PATH}/help-desk-audit/all-tickets`,
    title: 'sidenav.helpDeskAudit',
    fromBackend: 'HelpDesk Audit',
    navItem: false,
    icon: MessageOutlined,
    breadcrumb: false,
    submenu: []
  },
]

const accountingNavTree = [{
  key: 'accounting-group',
  path: `${APP_PREFIX_PATH}/accounting-group`,
  title: 'sidenav.accountingGroup',
  fromBackend: '',
  navItem: true,
  icon: false,
  breadcrumb: false,
  submenu: [
    {
      key: 'accounting',
      path: `${APP_PREFIX_PATH}/accounting`,
      title: 'sidenav.accounting',
      fromBackend: ['Accounting', 'Libraries'],
      navItem: true,
      icon: LayoutOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'accounting-exchange-rate',
          path: `${APP_PREFIX_PATH}/accounting/exchange-rate`,
          title: 'sidenav.accounting.exchangeRateList',
          fromBackend: 'Exchange Rate',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'accounting-income-expenditures',
          path: `${APP_PREFIX_PATH}/accounting/income-expenditures`,
          title: 'sidenav.accounting.incomeExpendituresList',
          fromBackend: 'Income/Expenditures',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'accounting-current-account-balance',
          path: `${APP_PREFIX_PATH}/accounting/current-account-balance`,
          title: 'sidenav.accounting.currentAccountBalance',
          fromBackend: 'Current Account Balance',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'accounting-consolidated-table',
          path: `${APP_PREFIX_PATH}/accounting/consolidated-table`,
          title: 'sidenav.accounting.consolidatedTable',
          fromBackend: 'Consolidated Table',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
      ]
    }
  ]
}]

const reportsNavTree = [{
  key: 'reports-group',
  path: `${APP_PREFIX_PATH}/reports-group`,
  title: 'sidenav.reportsGroup',
  fromBackend: '',
  navItem: true,
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'reports',
      path: `${APP_PREFIX_PATH}/reports`,
      title: 'sidenav.reports',
      fromBackend: 'Reports',
      navItem: true,
      icon: InfoCircleOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'reports-export-carfax-report',
          path: `${APP_PREFIX_PATH}/reports/export-carfax-report`,
          title: 'sidenav.reports.exportCarfaxReport',
          fromBackend: 'Carfax Report',
          navItem: false,
          icon: FileDoneOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reports-sales-reports',
          path: `${APP_PREFIX_PATH}/reports/sales-reports`,
          title: 'sidenav.reports.salesReports',
          fromBackend: 'Sales Reports',
          navItem: false,
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reports-invoices-list-lithuania',
          path: `${APP_PREFIX_PATH}/reports/invoices-list-lithuania`,
          title: 'sidenav.reports.invoicesListLithuania',
          fromBackend: 'Invoice Report',
          navItem: false,
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reports-partner-customers',
          path: `${APP_PREFIX_PATH}/reports/partner-customers`,
          title: 'sidenav.reports.partnerCustomers',
          fromBackend: 'Customers of Partners Report',
          navItem: false,
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}]

const dashBoardNavTree = [
  {
    key: 'dashboards-group',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboards',
    fromBackend: '',
    navItem: true,
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards',
        path: `${APP_PREFIX_PATH}/dashboards`,
        title: 'sidenav.dashboards',
        fromBackend: 'Dashboard',
        navItem: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'dashboards-shipping',
            path: `${APP_PREFIX_PATH}/dashboards/shipping`,
            title: 'sidenav.dashboards.shipping',
            fromBackend: 'Dashboard',
            navItem: false,
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'dashboards-sale-and-accounting',
            path: `${APP_PREFIX_PATH}/dashboards/sale-and-accounting`,
            title: 'sidenav.dashboards.saleAccounting',
            fromBackend: 'Dashboard',
            navItem: false,
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          },
        ],
      }
    ]
  }
];

const analyticsNavTree = [
  {
    key: 'analytics-group',
    path: `${APP_PREFIX_PATH}/analytics`,
    title: 'sidenav.analytics',
    fromBackend: '',
    navItem: true,
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'analytics',
        path: `${APP_PREFIX_PATH}/analytics`,
        title: 'sidenav.analytics',
        fromBackend: 'Analytics',
        navItem: true,
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'analytics-sale-and-accounting',
            path: `${APP_PREFIX_PATH}/analytics/sale-and-accounting`,
            title: 'sidenav.analytics.saleAccounting',
            fromBackend: 'Analytics',
            navItem: false,
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          },
        ],
      }
    ]
  }
];

const openContainersNavTree = [
  {
    key: 'open-containers-list',
    path: `${APP_PREFIX_PATH}/open-containers/open-containers-list`,
    title: 'sidenav.openContainers.openContainersList',
    fromBackend: 'Open Containers',
    navItem: false,
    icon: DropboxOutlined,
    breadcrumb: false,
    submenu: []
  },
];

const agreementsNavTree = [
  {
    key: 'agreements-group',
    path: `${APP_PREFIX_PATH}/agreements/agreements-list`,
    title: 'sidenav.agreements.agreementsList',
    fromBackend: 'Agreements',
    navItem: false,
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: []
  },
];

// const tempTestNavTree = [
//   {
//     key: 'tempTest',
//     path: `${APP_PREFIX_PATH}/test/convert-doc-to-pdf`,
//     title: 'sidenav.tempTest.convertDocToPdf',
//     fromBackend: '',
//     navItem: false,
//     icon: ToolOutlined,
//     breadcrumb: false,
//     submenu: []
//   },
// ];


const expeditionNavTree = [{
  key: 'expedition-group',
  path: `${APP_PREFIX_PATH}/expedition-group`,
  title: 'sidenav.expeditionGroup',
  fromBackend: '',
  navItem: true,
  icon: MergeCellsOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'expedition',
      path: `${APP_PREFIX_PATH}/expedition`,
      title: 'sidenav.expedition',
      fromBackend: 'Expedition',
      navItem: true,
      icon: MergeCellsOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'expedition-bremenList',
          path: `${APP_PREFIX_PATH}/expedition/bremen-list`,
          title: 'sidenav.expedition.bremenList',
          fromBackend: 'Bremen',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'expedition-rotterdamList',
          path: `${APP_PREFIX_PATH}/expedition/rotterdam-list`,
          title: 'sidenav.expedition.rotterdamList',
          fromBackend: 'Rotterdam',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'expedition-klaipedaList',
          path: `${APP_PREFIX_PATH}/expedition/klaipeda-list`,
          title: 'sidenav.expedition.klaipedaList',
          fromBackend: 'Klaipeda',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}];

const expeditionHistoryNavTree = [{
  key: 'expedition-history-group',
  path: `${APP_PREFIX_PATH}/expedition-history-group`,
  title: 'sidenav.expeditionHistoryGroup',
  fromBackend: '',
  navItem: true,
  icon: MergeCellsOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'expedition-history',
      path: `${APP_PREFIX_PATH}/expedition-history`,
      title: 'sidenav.expeditionHistory',
      fromBackend: 'Expedition-History',
      navItem: true,
      icon: MergeCellsOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'expedition-history-bremenList',
          path: `${APP_PREFIX_PATH}/expedition-history/bremen-list`,
          title: 'sidenav.expeditionHistory.bremenList',
          fromBackend: 'Bremen History',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'expedition-history-rotterdamList',
          path: `${APP_PREFIX_PATH}/expedition-history/rotterdam-list`,
          title: 'sidenav.expeditionHistory.rotterdamList',
          fromBackend: 'Rotterdam History',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'expedition-history-klaipedaList',
          path: `${APP_PREFIX_PATH}/expedition-history/klaipeda-list`,
          title: 'sidenav.expeditionHistory.klaipedaList',
          fromBackend: 'Klaipeda History',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}];


const dispatcherNavTree = [{
  key: 'dispatcher-group',
  path: `${APP_PREFIX_PATH}/dispatcher-group`,
  title: 'sidenav.dispatcherGroup',
  fromBackend: '',
  navItem: true,
  icon: CarOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dispatcher',
      path: `${APP_PREFIX_PATH}/dispatcher`,
      title: 'sidenav.dispatcher',
      fromBackend: 'Dispatcher',
      navItem: true,
      icon: CarOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'dispatcher-poland',
          path: `${APP_PREFIX_PATH}/dispatcher/poland-list`,
          title: 'sidenav.dispatcher.polandList',
          fromBackend: 'Poland',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'dispatcher-ukraine',
          path: `${APP_PREFIX_PATH}/dispatcher/ukraine-list`,
          title: 'sidenav.dispatcher.ukraineList',
          fromBackend: 'Ukraine',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'dispatcher-others',
          path: `${APP_PREFIX_PATH}/dispatcher/others-list`,
          title: 'sidenav.dispatcher.othersList',
          fromBackend: 'Others',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'dispatcher-car-transporters',
          path: `${APP_PREFIX_PATH}/dispatcher/car-transporters-list`,
          title: 'sidenav.dispatcher.carTransportersList',
          fromBackend: 'Car transporters',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'dispatcher-tow-trucks',
          path: `${APP_PREFIX_PATH}/dispatcher/tow-trucks-list`,
          title: 'sidenav.dispatcher.towTrucksList',
          fromBackend: 'Tow trucks',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}];


const dispatcherHistoryNavTree = [{
  key: 'dispatcher-history-group',
  path: `${APP_PREFIX_PATH}/dispatcher-history-group`,
  title: 'sidenav.dispatcherHistoryGroup',
  fromBackend: '',
  navItem: true,
  icon: CarOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dispatcher-history',
      path: `${APP_PREFIX_PATH}/dispatcher-history`,
      title: 'sidenav.dispatcherHistory',
      fromBackend: 'Dispatcher-History',
      navItem: true,
      icon: CarOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'dispatcher-poland-history',
          path: `${APP_PREFIX_PATH}/dispatcher-history/poland-list`,
          title: 'sidenav.dispatcherHistory.polandHistoryList',
          fromBackend: 'Poland History',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'dispatcher-ukraine-history',
          path: `${APP_PREFIX_PATH}/dispatcher-history/ukraine-list`,
          title: 'sidenav.dispatcherHistory.ukraineHistoryList',
          fromBackend: 'Ukraine History',
          navItem: false,
          icon: null,
          breadcrumb: false,
          submenu: []
        },
      ]
    },
  ]
}];


const navigationConfig = [
  ...dashBoardNavTree,
  ...shippingNavTree,
  ...shippingLastVehiclesNavTree,
  ...openContainersNavTree,
  ...usersNavTree,
  ...paymentsNavTree,
  ...expeditionNavTree,
  ...expeditionHistoryNavTree,
  ...dispatcherNavTree,
  ...dispatcherHistoryNavTree,
  ...requestsNavTree,
  ...librariesNavTree,
  ...toolsNavTree,
  ...reportsNavTree,
  ...helpDeskNavTree,
  ...helpDeskAuditNavTree,
  ...analyticsNavTree,
  ...accountingNavTree,
  ...purchasedCarNavTree,
  ...bidsNavTree,
  ...agreementsNavTree,
  // ...tempTestNavTree,
]

export default navigationConfig;


export const getNavigationConfig = (navigationConfig, navPermissions) => {

  const newNavigationConfig = _.cloneDeep(navigationConfig);

  const markNavigationConfig = (searchBackendName, searchParentBackendName='' ) => {

    const markingProcess = (navigationConfig, parentItemNavigation='') => {
      // console.log('markNavigationConfig->',
      //   'searchBackendName:', searchBackendName,
      //   'searchParentBackendName:',searchParentBackendName,
      //   'parentItemNavigation:', parentItemNavigation,
      //   'newNavigationConfig:', navigationConfig)

      navigationConfig.forEach((item) => {
        if (((parentItemNavigation === searchParentBackendName ||
          (parentItemNavigation?.includes && parentItemNavigation.includes(searchParentBackendName))) && item?.fromBackend === searchBackendName) || item?.navItem === true) {
          item.isLeave = true;
        }
        // if (item?.key === 'helpDesk' || item?.key === 'helpDeskAudit' || item?.key === 'assignee-category') {
        //   item.isLeave = true;
        // }
        // if (item?.key === 'export-carfax-report') {
        //   item.isLeave = true;
        // }

        // if (item?.key === 'libraries-auction-account') {
        //   item.isLeave = true;
        // }

        if (item?.key === 'tempTest') {
          item.isLeave = true;
        }

        markingProcess(item.submenu, item?.fromBackend ?? '');
      })
    }

    markingProcess(newNavigationConfig);

  }

  const iteratePermissions = (navPermissions, parentItemPermission='') => {

    for (let itemPermission in navPermissions) {

      if( navPermissions.hasOwnProperty(itemPermission)) {

        markNavigationConfig(itemPermission.trim(), parentItemPermission);

         // console.log('parentItemPermission:', parentItemPermission, 'itemPermission:',itemPermission)
        if( typeof navPermissions[itemPermission] === 'object' ) {
          iteratePermissions(navPermissions[itemPermission], itemPermission.trim());
        }

      }

    }

  }

  const filterAllowedNavigationConfig = (newNavigationConfig) => {
    const result = [];
    let subMenu;
    let newItem;
    for(let i = 0; i < newNavigationConfig.length; i++) {
      if (_.isObject(newNavigationConfig[i]) && newNavigationConfig[i]?.isLeave === true ) {
        subMenu = [];

        if (newNavigationConfig[i]?.submenu !== undefined &&
            _.isArray(newNavigationConfig[i].submenu) && !_.isEmpty(newNavigationConfig[i].submenu)) {
          subMenu = filterAllowedNavigationConfig(newNavigationConfig[i].submenu);
        }
        if (newNavigationConfig[i]?.navItem === true &&
          _.isEmpty(subMenu)) {
          continue;
        }

        newItem = {...newNavigationConfig[i]};
        if (!_.isEmpty(subMenu)) {
          newItem.submenu = subMenu;
        }

        // console.log('newNavigationConfig[i]:',newNavigationConfig[i])
        result.push(newItem);
      }
    }
    return result;
  }

  iteratePermissions(navPermissions)

  // console.log('newNavigationConfig:', newNavigationConfig);

  return filterAllowedNavigationConfig(newNavigationConfig);
}


